// Team.js
import React, { useEffect } from 'react';
import './Team.css';
import 'aos/dist/aos.css'; // Import AOS animations
import AOS from 'aos';
import { Carousel } from 'react-bootstrap';

// Importer les images dans src
import Image1 from '../../assets/team/avatar.jpeg';
import Image2 from '../../assets/team/avatar.jpeg';
import Image3 from '../../assets/team/avatar.jpeg';
import Image4 from '../../assets/team/avatar.jpeg';

const teamMembers = [
  {
    id: 1,
    image: Image1, 
    name: 'Hugues HOUNSOU',
    profession: 'Directeur Général',
    description: 'Passionnée par le développement front-end et les nouvelles technologies.'
  },
  {
    id: 2,
    image: Image2,
    name: 'Lookman SALAMI',
    profession: 'Directeur Technique',
    description: 'Expert en UX/UI design, avec un sens aigu de l’esthétique et de la fonctionnalité.'
  },
  {
    id: 3,
    image: Image3,
    name: 'Pierre C. ADJOVI',
    profession: 'Responsable Marketing',
    description: 'Stratégie marketing innovante et approche axée sur les données.'
  },
  {
    id: 4,
    image: Image4,
    name: 'Francois HOUNSOU',
    profession: 'Chef de Projet',
    description: 'Chef de projet expérimenté avec un fort leadership et esprit d’équipe.'
  }
];

const Team = () => {
  useEffect(() => {
    AOS.init({ duration: 800, delay: 200 });
  }, []);
      
  return (
    <section id="team" className="team-section py-5">
        <div className="container">
            <div className="text-center mb-4">
                <h2 className="team-title" data-aos="fade-down">Team</h2>
                <p className="team-subtitle" data-aos="fade-down" data-aos-delay="100">
                    L'équipe digitale est composée des membres suivants
                </p>
            </div>

            <div className="row"> 
              <div className="col-lg-2 col-md-12"></div>
              <div className="col-lg-8 col-md-12">

                <Carousel indicators={true}>
                    {teamMembers.map((member) => (
                        <Carousel.Item key={member.id}>
                            <div className="team-member d-flex align-items-center">
                                <div className="team-member-info">
                                    <p className="team-member-description">
                                        <i className="bi bi-quote quote-icon-left"></i>
                                            <span>{member.description}</span>
                                        <i className="bi bi-quote quote-icon-right"></i>
                                    </p>
                                    <h3 className="team-member-name">{member.name}</h3>
                                    <h4 className="team-member-profession">{member.profession}</h4>
                                </div>
                                <div className="team-member-photo-wrapper">
                                    <img src={member.image} alt={member.name} className="team-member-photo" />
                                </div>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>

              </div>

              <div className="col-lg-2 col-md-12"></div>
            </div>
        </div>
    </section>
  );
};

export default Team;
